/* eslint-disable sonarjs/cognitive-complexity */

'use strict';

var formHelpers = require('../checkout/formErrors');
var utils = require('../checkout/utils');

var invalidatefieldcount = 0;
var invalidateFieldCountPage = 0;

var formGroupStr = '.form-group';
var invlaidFeedbackStr = '.invalid-feedback';
var patternMismatchStr = 'pattern-mismatch';
var inputAndSelectStrs = 'input, select';
var isInvalidFirstStr = '.is-invalid:first';
var rangeErrorStr = 'range-error';
var missingErrorStr = 'missing-error';
var regModalStr = 'registration-modal';
var isInvalidStr = 'is-invalid';

var validateDate = function (dateInput) {
    var valid = true;
    // eslint-disable-next-line no-param-reassign
    dateInput.invalid = false;
    if (dateInput.pattern) {
        var pattern = new RegExp(dateInput.pattern);
        if (!pattern.test(dateInput.value)) {
            // eslint-disable-next-line no-param-reassign
            dateInput.invalid = true;
            $(dateInput).trigger('invalid', false);
            valid = false;
        }
    }
    return valid;
};

var validateTel = function (telInput) {
    var valid = true;
    var phone = '';
    var isCVS = !!$('.shipping-type-cvs').length;
    if (telInput.value.indexOf('(') === 0) {
        phone = window.Inputmask.unmask(telInput.value, { mask: ' (999) 999-9999' });
    } else {
        // strip out all spaces or non-digit characters
        phone = telInput.value.replace(/\D+/g, '');
    }

    if (telInput.dataset.phoneRegex && isCVS) {
        var invalidChar = $(telInput).data('invalid-chars');
        var phoneRegex = telInput.dataset.phoneRegex;
        var pattern = new RegExp(phoneRegex);

        if (!pattern.test(phone)) {
            telInput.setCustomValidity(invalidChar);
            $(telInput).parents(formGroupStr).find(invlaidFeedbackStr).text(invalidChar);
            $(telInput).trigger('invalid', false);
            valid = false;
        }
    }

    var telLength = telInput.attributes.minlength ? parseInt(telInput.attributes.minlength.value, 10) : 0;
    if (!window.isNaN(phone) && phone.length < telLength) {
        var error = $(telInput).data(patternMismatchStr);
        telInput.setCustomValidity(error);
        $(telInput).trigger('invalid', false);
        valid = false;
    }
    return valid;
};

/**
 * Scrolls to the first error in the form if form has errors
 * @param {element} $this - submit button which was clicked
 * @param {element} $contextElement - Element contains errors
 */
function scrollToErrorInPage($this, $contextElement) {
    var form = $this.parents('form');
    if (!(form.hasClass(regModalStr) && form.hasClass('login-modal')) && form.hasClass('complete-reg')) {
        var errorElementSelector;
        errorElementSelector = '.complete-reg input.is-invalid';
        var clientError = $(form).find('input').hasClass(isInvalidStr);
        if (clientError && $(errorElementSelector).first().length > 0) {
            var scrollPage = $(errorElementSelector).first().offset().top;
            $(window).scrollTop(scrollPage - 110);
        }
    } else if ($contextElement || (form && form.length)) {
        var $contextWrapper = $contextElement && $contextElement.length ? $contextElement : $(form);
        var $errorElementsInForm = $contextWrapper.find('.is-invalid');
        if ($errorElementsInForm.length) {
            var scrollPosition = $errorElementsInForm.first().offset().top;
            $(window).scrollTop(scrollPosition - 110);
        }
    }
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @param {boolean} validateFieldsOnly - Validate fields only the div
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event, validateFieldsOnly) {
    var valid = true;
    formHelpers.clearPreviousErrors($(this).closest('form')[0]);
    if (validateFieldsOnly) {
        var form = $(this).closest('form')[0];
        if (form.checkValidity && !form.checkValidity()) {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }

            $(this).find(inputAndSelectStrs).each(function () {
                if (!this.validity.valid) {
                    valid = false;
                    $(this).trigger('invalid', this.validity);
                }
                if (this.type === 'date' && (this.value !== '' || this.value !== null)) {
                    valid = validateDate(this);
                }
                if (this.type === 'tel') {
                    valid = validateTel(this);
                }
            });
            $(isInvalidFirstStr).trigger('focus');
        }
    } else if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        $(this).find(inputAndSelectStrs).each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }

            if (this.type === 'date' && (this.value !== '' || this.value !== null)) {
                valid = validateDate(this);
            }

            if (this.type === 'tel') {
                valid = validateTel(this);
            }
        });
        $(isInvalidFirstStr).trigger('focus');
    } else {
        $(this).find('input[type="date"]').each(function () {
            if (this.value !== '' || this.value !== null) {
                valid = validateDate(this);
            }
        });

        $(this).find('input[type="tel"]').each(function () {
            valid = validateTel(this);
        });
        $(isInvalidFirstStr).trigger('focus');
    }
    return valid;
}

/**
 * Validate a partial form.
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validatePartialForm(event) {
    var valid = true;
    if (event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
    }
    $(this).find(inputAndSelectStrs).each(function () {
        if (this.validity && !this.validity.valid) {
            valid = false;
            $(this).trigger('invalid', this.validity);
        }
        if (this.type === 'tel') {
            valid = validateTel(this);
        }
    });
    $(isInvalidFirstStr).trigger('focus');
    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid, .form-check-input.is-invalid, .custom-control-input.is-invalid').removeClass(isInvalidStr).each(function () {
        this.setCustomValidity('');
    });
}

/**
 * Scrolls to the first error in the form if form has errors
 * @param {element} $this - submit button which was clicked
 */
function scrollToErrorInModal($this) {
    var form = $this.parents('form');
    if (form.hasClass(regModalStr) || form.hasClass('login-modal')) {
        var isRegistration = $this.parents('form').hasClass(regModalStr);
        var selector;
        var errorElementSelector;
        if (isRegistration) {
            if ($('#terms-conditions').prop('checked') && $('.terms-error')) {
                $('.terms-error').empty();
            }
            selector = '.login-page .registration-modal';
            errorElementSelector = '.registration-modal input.is-invalid';
        } else {
            selector = '.login-page .login-modal';
            errorElementSelector = '.login-modal input.is-invalid';
        }
        var clientError = $(selector).find('input').hasClass(isInvalidStr);
        if (clientError && $(errorElementSelector).first().length > 0) {
            var elementToScroll = $this.parents('form').find('input.is-invalid').first().attr('id');
            var elementToScrollSelector = $('#' + elementToScroll)[0];
            elementToScrollSelector.scrollIntoView();
        }
    }
}

/**
 * on Invalid callback - set right error message
 * @param {Event} e - invalid event object
 */
function invalidCallBack(e) {
    e.preventDefault();
    if (($(this).data('email-mismatch') !== undefined && !$(this).data('email-mismatch')) || this.type !== 'tel') {
        this.setCustomValidity('');
    }
    if (!this.validity.valid || this.invalid) {
        var validationMessage = this.validationMessage;
        if (this.id === 'securityCode' && $('#securityCodeErrorMsg').length > 0 && window.ccType === 'maestro') {
            validationMessage = $('#securityCodeErrorMsg').data('maestroSecurityCodeErrorMsg');
        }
        $(this).addClass(isInvalidStr);
        if (this.id === 'birthday') {
            validationMessage = document.getElementById('birthdayValidationMessage').value;
        }
        if (this.validity.patternMismatch && $(this).data(patternMismatchStr)) {
            validationMessage = $(this).data(patternMismatchStr);
        }
        if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
            && $(this).data(rangeErrorStr)) {
            validationMessage = $(this).data(rangeErrorStr);
        }
        if ((this.validity.tooLong || this.validity.tooShort)
            && $(this).data(rangeErrorStr)) {
            validationMessage = $(this).data(rangeErrorStr);
        }
        if (this.validity.valueMissing && $(this).data(missingErrorStr)) {
            validationMessage = $(this).data(missingErrorStr);
        }
        if (this.id === 'terms-conditions' && $(this).data('terms-error')) {
            validationMessage = $(this).data('terms-error');
            $(this).parents(formGroupStr).find('.terms-error.pt-2')
                .text(validationMessage);
        }
        var isCheckout = !!(this.name.indexOf('shipping') > -1 || this.name.indexOf('billing') > -1 || this.name.indexOf('gift') > -1 || this.className.indexOf('track-order-status') > -1);
        if (this.validity.patternMismatch && isCheckout) {
            var invalidChar = $(this).data('invalid-chars');
            var pattern = new RegExp(this.pattern);
            var inputValue = this.value.split('');
            inputValue.forEach(function (char) {
                if (!pattern.test(char)) {
                    invalidChar += char;
                }
            });
            $(this).parents(formGroupStr).find(invlaidFeedbackStr)
                .text(invalidChar);
        } else {
            $(this).parents(formGroupStr).find(invlaidFeedbackStr)
                .text(validationMessage);
        }

        if ((this.className.indexOf('custom-zipcode-or') > -1) && ((this.validity.patternMismatch && $(this).data(patternMismatchStr)) || (this.validity.valueMissing && $(this).data(missingErrorStr)))) {
            $('.find-order-form').find('.error-container').empty();
            var errorShipping = $('.find-order-form').find('.error-shipping');
            if (!errorShipping.hasClass('d-none')) {
                errorShipping.addClass('d-none');
            }
        }

        // scroll to the first error of the form
        if (invalidatefieldcount === 0) {
            var submitbutton = $(this).closest('form').find('button[type="submit"], input[type="submit"]');
            scrollToErrorInModal(submitbutton);
            invalidatefieldcount = 1;
        }

        if (invalidateFieldCountPage === 0) {
            var submitbtn = $(this).closest('form').find('button[type="submit"], input[type="submit"]');
            scrollToErrorInPage(submitbtn);
            invalidateFieldCountPage = 1;
        }
    }
}

/**
 * Clears invalid classes, clears setCustomValidity.
 * @param {element} formField - Form to be cleared
 * @returns {void}
 */
function clearFieldInvalidState(formField) {
    var $formField = $(formField);
    $formField.removeClass(isInvalidStr);
    $formField.parents(formGroupStr).removeClass(isInvalidStr);
    formField.setCustomValidity('');
}

/**
 * Handles on blur out event.
 * @returns {void}
 */
function handleOnBlur() {
    var $formField = $(this);
    $formField.val($formField.val().trim());
    if (this.id === 'birthday' && this.value !== '') {
        validateDate(this);
    }
    if (!this.validity.valid || this.invalid) {
        $formField.trigger('invalid', this.validity);
        utils.tealiumErrorTracking(this);
    } else {
        clearFieldInvalidState(this);
    }
}

module.exports = {
    invalid: function () {
        $(document).on('invalid', 'form input, form select', invalidCallBack);
    },

    submit: function () {
        $('form').on('submit', function (e) {
            invalidatefieldcount = 0;
            invalidateFieldCountPage = 0;
            return validateForm.call(this, e);
        });
    },

    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            // clear all errors when trying to submit the form
            invalidatefieldcount = 0;
            invalidateFieldCountPage = 0;
            clearForm($(this).parents('form'));
        });
    },

    onSelectClick: function () {
        $('select').on('change', function () {
            this.setAttribute('value', this.value);
        });
    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        validateClientForm: function (form, event, validateFieldsOnly) {
            return validateForm.call(form, event || null, validateFieldsOnly);
        },
        validatePartialForm: function (form, event) {
            return validatePartialForm.call($(form), event || null);
        },
        clearForm: clearForm,
        scrollToErrorInPage: scrollToErrorInPage,
        handleOnBlur: handleOnBlur
    }
};
