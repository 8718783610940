/* eslint-disable sonarjs/cognitive-complexity */

'use strict';

var isPDP = $('[data-action="Product-Show"]').length > 0;
var isCart = $('[data-action="Cart-Show"]').length > 0;
var currentUUID;
var currentShipmentUUID = null;

// ID Selectors
const storeFinderModalID = '#storeFinderModal';

// Class Selectors
const searchStoreClass = '.js-search-store';
const btnSelectStoreClass = '.btn-select-store';
const btnStoreLocatorSearchClass = '.btn-storelocator-search';
const inStoreLocatorNoResultsClass = '.store-locator-no-results';
const inStoreInventoryDialogClass = '.in-store-inventory-dialog';
const storeFinderClass = '.js-store-finder';
const updateStoreAreaClass = '.update-store-area';

// Multi-class Selectors
const storeLocatorClasses = '.store-locator-container form.store-locator';

// Attribute Selectors
const dataSearchPidAttr = 'data-search-pid';

// Data Selectors
const actionURLDataSelector = 'action-url';
const postalCodeFormSelector = '[name="postalCode"]';

/**
 * show resetStoreSearchFormResults modal from global header link
 */
function resetStoreSearchFormResults() {
    var $form = $(storeLocatorClasses);
    var $zipCode = $form.find(postalCodeFormSelector);
    $form.find(btnStoreLocatorSearchClass).attr(dataSearchPidAttr, '');
    $zipCode.val('').trigger('change');
    $('.results-card').addClass('d-none');
    $(updateStoreAreaClass).addClass('d-none');
    $('.results').empty().data({ 'has-results': '', radius: '', 'search-key': '' });
}

/**
 * show store finder modal from global header link
 */
function showStoreFinder() {
    $(storeFinderClass).on('click', function () {
        if (isCart || isPDP) {
            resetStoreSearchFormResults();
        }
        $(storeFinderModalID).modal('show');
        $('#store-postal-code').trigger('focus');
    });
}
/**
 * Blur validation of postal code input field
 */
function postalCodeBlur() {
    var $form = $(storeLocatorClasses);
    var $zipCode = $form.find(postalCodeFormSelector);
    $zipCode.on('change blur', function () {
        var zipCodeData = $zipCode.val();
        if (zipCodeData && zipCodeData.length > 0) {
            $form.find(searchStoreClass).removeAttr('disabled');
        } else {
            $form.find(searchStoreClass).attr('disabled', true);
        }
    });
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data) {
    if (data) {
        var $resultsDiv = $('.results');
        var hasResults = data.stores.length > 0;
        var $resultsSection = $('.results-card');
        $resultsSection.removeClass('d-none');
        if (!hasResults) {
            if (data.noStoreResultMessagingHTML) {
                $(inStoreLocatorNoResultsClass).html(data.noStoreResultMessagingHTML);
            }
            $(inStoreLocatorNoResultsClass).show();
            $(updateStoreAreaClass).addClass('d-none');
            // $(btnSelectStoreClass).prop('disabled', true);
        } else {
            $(inStoreLocatorNoResultsClass).hide();
            $(updateStoreAreaClass).removeClass('d-none');
        }

        $resultsDiv.empty().data({ 'has-results': hasResults, radius: data.radius, 'search-key': data.searchKey });

        if (data.storesResultsHtml) {
            $resultsDiv.append(data.storesResultsHtml);
        }
    }
}

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function searchStore(element) {
    var dialog = element.closest(inStoreInventoryDialogClass);
    var spinner = dialog.length ? dialog.spinner() : $.spinner();
    spinner.start();
    var $form = element.closest('.store-locator');
    var radius = $('.results').attr('data-radius') ? parseInt($('.results').attr('data-radius'), 10) : 50;
    var url = $form.attr('action');
    var urlParams = { radius: radius };
    if (isPDP || isCart) {
        var pid = $form.find(searchStoreClass).data('search-pid') || null;
        var ats = $form.find(searchStoreClass).data('search-ats') || false;
        urlParams.products = pid;
        urlParams.ats = ats;
    }
    var requestedPostalCode = $form.find(postalCodeFormSelector).val();

    // Save requested Postal Code and update DOM elements with new data
    if (window.sitePrefs.bopisEnabled) {
        document.cookie = 'requestedPostalCode=' + requestedPostalCode + '; path=/';
        $('.change-store').each(function () {
            $(this).data('postal', requestedPostalCode);
        });
    }

    var payload = $form.is('form') ? $form.serialize() : { postalCode: requestedPostalCode };

    url = appendToUrl(url, urlParams);

    $.ajax({
        url: url,
        type: $form.attr('method'),
        data: payload,
        dataType: 'json',
        success: function (data) {
            spinner.stop();
            updateStoresResults(data);
            // $(btnSelectStoreClass).prop('disabled', true);
        }
    }).done(function () {
        spinner.stop();
    });
    return false;
}

/**
 * Trigger radius change event and update new store list
 *
 * NOTE: Unused due to CHO-562: radius selector removed and search radius
 * is always 50 units (mi/km). Preserved should the option to choose search
 * radius be restored.
 */
function changeRadius() {
    $('.store-locator-container .radius').on('change', function () {
        var radius = $(this).val();
        var searchKeys = $('.results').data('search-key');
        var currentPostalCode = $('#store-postal-code[name="postalCode"]').val();
        var url = $(this).data(actionURLDataSelector);
        var urlParams = {};

        urlParams = {
            radius: radius,
            postalCode: currentPostalCode,
            lat: searchKeys.lat,
            long: searchKeys.long
        };

        if (isPDP || isCart) {
            var pid = $(searchStoreClass).data('search-pid') || null;
            var ats = $(searchStoreClass).data('search-ats') || false;
            urlParams.products = pid;
            urlParams.ats = ats;
        }

        url = appendToUrl(url, urlParams);
        var dialog = $(this).closest(inStoreInventoryDialogClass);
        var spinner = dialog.length ? dialog.spinner() : $.spinner();
        spinner.start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                spinner.stop();
                updateStoresResults(data);
                // $(btnSelectStoreClass).prop('disabled', true);
            }
        }).done(function () {
            spinner.stop();
        });
    });
}

/**
 * Updates the bopis store per line item on the cart
 * @param {string} url - route to update the store
 * @param {Object} data - object containing current line item data
 */
function updateStoreForLineItem(url, data) {
    $.spinner().start();
    $.ajax({
        url: appendToUrl(url, {
            storeId: data.storeID,
            shipmentId: data.currentShipmentUUID,
            productLineItemId: data.currentLineItemUUID
        }),
        type: 'get',
        dataType: 'json',
        success: function (res) {
            $('#inStoreInventoryModal').modal('hide');
            $('#inStoreInventoryModal').remove();

            // Update line item on cart
            if (isCart) {
                $('.product-info.uuid-' + data.currentLineItemUUID).replaceWith(res.updatedProductCard);
                setTimeout(function () {
                    $(storeFinderModalID).modal('hide');
                });
                $.spinner().stop();
            } else {
                /* @to-do: for now, the safest option is to just trigger a page reload if the user changes store on a line item within checkout, otherwise we'll need to figure out exactly what parts of the DOM / form we need to update with the updated shipment information */
                window.location.reload();
            }
        }
    });
}

/**
 * Search stores form submit event and button click event.
 */
function searchStoreEvent() {
    $(storeLocatorClasses).on('submit', function (e) {
        e.preventDefault();
        searchStore($(this));
    });
    $('.store-locator-container .btn-storelocator-search[type="button"], .store-locator-container .js-search-store').on('click', function (e) {
        e.preventDefault();
        searchStore($(this));
    });
}

/**
 * Ajax call Select store on button click for Account-SetPreferredStore to store route with response
 */
function selectStore() {
    $('body').on('click', btnSelectStoreClass, function (e) {
        e.preventDefault();
        var $button = $(this);
        var spinner = $(inStoreInventoryDialogClass).spinner();
        var url = $button.data('href');
        var actionUrl = $button.data(actionURLDataSelector);
        var requestedStoreId = $button.data('store-id');
        var preferredStoreId = { preferredStoreId: requestedStoreId };
        var isLineItemStoreSelection = false;
        const isSoldOutProduct = $('#notify-cart-product').is(':visible');
        const isSoldOutMaster = $('#notify-cart-master').is(':visible');

        if (isCart) {
            isLineItemStoreSelection = true;
        }

        if (isPDP || isCart) {
            var modal = $button.parents('.select-store-modal');
            var currentLineItemUUID = isLineItemStoreSelection ? modal.attr('data-current-uuid') : null;
            currentShipmentUUID = isLineItemStoreSelection ? modal.attr('data-current-shipment-uuid') : null;
            var selectedStore = $button;
            var storeData = {
                storeID: selectedStore.data('store-id'),
                searchRadius: $('.results').data('radius'),
                searchPostalCode: $('.results').data('search-key').postalCode,
                storeDetailsHtml: selectedStore.siblings('label').html(),
                currentLineItemUUID: currentLineItemUUID,
                currentShipmentUUID: currentShipmentUUID,
                event: e
            };

            $('.js-emptyStore').removeClass('d-flex').addClass('d-none');
            $('.js-selected-store-details').removeClass('d-none');

            if (!isLineItemStoreSelection && isPDP) {
                $('body').trigger('store:selected', storeData);
                $('.js-radio-btn').trigger('change');
            } else {
                updateStoreForLineItem(actionUrl, storeData); // for cart pages
                return;
            }
        }
        spinner.start();
        $.ajax({
            url: url,
            type: 'post',
            data: preferredStoreId,
            dataType: 'json',
            success: function (data) {
                if (!data.error) {
                    // Update selected store name in global header on desktop and side menu on mobile.
                    var selectedStoreName = data.customerPreferredStore.name;
                    var $selectedStoreCont = $('.js-selected-store');
                    var $selectedStore = $selectedStoreCont.find('.js-store-name');
                    var $selectStoreLabel = $('.js-select-store-label');
                    $selectedStoreCont.removeClass('d-none').addClass('d-inline-block');
                    $selectedStore.text(selectedStoreName).attr({ title: selectedStoreName, 'aria-label': selectedStoreName });
                    $selectStoreLabel.addClass('d-none').removeClass('d-inline-block');
                } else {
                    /* @to-do: handle error scenario to show error message if selected store is not saved in session. */
                }
                setTimeout(function () {
                    $(storeFinderModalID).modal('hide');
                });
                spinner.stop();
                // reload page only if product is OOS online to refresh PDP with store's stock
                if (isSoldOutProduct || isSoldOutMaster) {
                    window.location.reload();
                }
            },
            error: function (err) {
                /* @to-do: handle error scenario to show error message if there is server side error */
                // eslint-disable-next-line no-console
                console.error('Error in setting preferred store: ' + err.message);
                spinner.stop();
            }
        });
    });
}

/**
* This function is to handle change store click event on PDP page.
*/
function changeStorePDPLink() {
    $('body').on('click', '.change-store', function () {
        $(storeFinderClass).trigger('click');
        var $this = $(this);
        var pid = !isPDP ? $this.data('pid') : $this.closest('.product-detail').attr('data-pid');
        var validateATS = $this.data('validate-ats');
        if (!isPDP && isCart) {
            currentUUID = $this.data('uuid');
            currentShipmentUUID = $this.data('shipment-uuid');
            var actionUrl = $this.data(actionURLDataSelector);
            var $form = $('.store-locator');
            $form.attr('action', actionUrl);
        }
        $(btnStoreLocatorSearchClass).attr(dataSearchPidAttr, pid);
        $(btnStoreLocatorSearchClass).attr('data-search-ats', validateATS);
        var modal = $('.modal-body.select-store-modal');
        modal.attr('data-current-uuid', currentUUID).attr('data-current-shipment-uuid', currentShipmentUUID);
    });
}

/**
* This function will add necessary attributes to search modal for store finder w.r.t product.
*/
function initStoreParamsAndContent() {
    var pid = isPDP ? $('.product-detail').attr('data-pid') : false;
    $(btnStoreLocatorSearchClass).attr(dataSearchPidAttr, pid);

    // to hide store details on mobile for cart page
    if (isCart) {
        $(storeFinderClass).addClass('d-none').removeClass('d-flex');
    }
}

module.exports = {
    showStoreFinder: showStoreFinder,
    searchStoreEvent: searchStoreEvent,
    postalCodeBlur: postalCodeBlur,
    changeRadius: changeRadius,
    updateStoresResults: updateStoresResults,
    selectStore: selectStore,
    changeStorePDPLink: changeStorePDPLink,
    updateSelectStoreButton: function () {
        $('body').on('click', (function () {
            $(btnSelectStoreClass).prop('disabled', false);
        }));
    },
    initStoreParamsAndContent: initStoreParamsAndContent
};
